<template>
  <div>
    <div v-if="discount.is_active" class="paragraph-1 mt-lg-4 text-right">
      {{ $t('Voucher') }}:
      <span class="pl-3">
        {{ discount_price | currency(discount.discount_amount.currency) }}
      </span>
    </div>
    <div class="paragraph-1 mt-lg-2 text-right">
      {{ $t('Price') }}:
      <span class="pl-3">
        {{ total_price | currency(booking_amount.currency) }}
      </span>
      <div class="small">{{ $t('InclVat') }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BookingPrice',
  props: {
    booking_details: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['loading', 'booked_game', 'booking_amount', 'discount']),
    discount_price() {
      return this.booking_details.players <
        this.booked_game.min_players_per_team
        ? 0
        : this.discount.discount_amount.amount;
    },
    total_price() {
      return this.booking_details.players <
        this.booked_game.min_players_per_team
        ? 0
        : this.booking_amount.amount;
    },
  },
};
</script>
